<div
  [class.row-reverse]="position() === 'left'"
  class="card at-flex at-flex-column at-gap-lg at-p-lg at-bg-neutral-50 at-radius-xxxl">
  <div
    class="card-info md:at-px-3xl at-py-lg at-flex at-flex-column at-justify-center">
    @if (badgeLabel()) {
      <mars-badge
        [label]="badgeLabel()"
        badgeClass="at-mb-3xl at-px-md at-py-x-sm at-radius-xxxl at-bg-success-500 at-text-white at-fw-medium" />
    }

    @if (title()) {
      <h3
        class="at-mb-sm at-text-lg md:at-h4 xl:at-h3 rtl:md:at-h5 at-fw-semibold md:at-fw-bold at-text-primary-500">
        {{ title() }}
      </h3>
    }

    @if (description()) {
      <p class="at-text-sm md:at-text-md xl:at-text-lg at-text-neutral-700">
        {{ description() }}
      </p>
    }
  </div>
  <div class="img-container">
    <img class="at-w-100" [src]="image()" [alt]="title()" />
  </div>
</div>
